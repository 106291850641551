const SPTest = () => {
    return (
        <div>
            <p className="text-[24px]">Test upload</p>
            <input type="file" />
        </div>
    );
};

export default SPTest;
