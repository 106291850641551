import { FC } from 'react';
import useQuoteDetail, { Props, ReceivedProps } from './hook';
import { QuoteDetailStyles } from './styled';
import ImageCarousel from 'components/ImageCarousel';
import { QuoteStatusEnum, QuoteTypeEnum } from 'utils/enums';
import pdfFileIcon from 'assets/icons/pdf-file.svg';
import { useNavigate } from 'react-router-dom';
import TextArea from 'antd/lib/input/TextArea';
import moment from 'moment';
import styled from 'styled-components';
import querystring from 'query-string';
import clsx from 'clsx';

const TextAreaCustom = styled(TextArea)`
    border: none !important;
    :hover {
        border: none !important;
    }
`;

const SPQuoteDetailLayout: FC<Props> = ({ data }) => {
    const queryObj = querystring.parse(location.search);
    const images: any =
        data?.images && data?.images.map((item) => item.image_path);
    let resultImage: any = [];
    for (let i = 0; i < images?.length; i += 3) {
        resultImage.push(images?.slice(i, i + 3));
    }

    const navigate = useNavigate();
    const parseNamePdf = (file_path: any) => {
        const arrName: any = [];
        const string: any = decodeURIComponent(file_path);
        if (string !== null && string !== undefined) {
            for (let i = string?.length - 1; i > 0; i--) {
                if (string[i] !== '/') {
                    arrName.push(string[i]);
                } else {
                    break;
                }
            }
        }
        return arrName
            .slice()
            .reverse()
            .toString()
            .replaceAll(',', '')
            .split('.')[0];
    };
    return (
        <QuoteDetailStyles>
            <div className="rounded-2xl py-2 px-1 wrapperBox">
                <div className="uploadedImages !mb-[15px]">
                    <ImageCarousel
                        images={
                            data?.images &&
                            data?.images.map((item) => item.image_path)
                        }
                    />
                </div>

                <div className="quoteDetailWrapper">
                    <div className="flex justify-between pb-[10px]">
                        <div className="text-[16px] font-medium">依頼内容</div>
                        <div
                            className="min-w-[90px] text-center self-baseline text-[12px]"
                            style={{
                                color: QuoteStatusEnum[
                                    data?.status || 'requesting'
                                ]?.textColor,
                                backgroundColor:
                                    QuoteStatusEnum[
                                        data?.status || 'requesting'
                                    ]?.bgColor,
                                borderRadius: '15px',
                                padding: '4px 12px',
                            }}
                        >
                            {QuoteStatusEnum[data?.status || 'initial']?.title}
                        </div>
                    </div>

                    <div className="!pb-[5px]">
                        <TextAreaCustom
                            value={data?.content}
                            className="!bg-[#F5F5F5] !text-[rgba(0,0,0,0.5)]"
                            rows={3}
                            readOnly
                        />
                    </div>

                    <div className="text-[14px] font-normal py-[2px]">
                        <span className="text-[#000000]">タイプ: &nbsp;</span>
                        <span>
                            {
                                QuoteTypeEnum[data?.quote_type || 'interior']
                                    ?.title
                            }
                        </span>
                    </div>

                    {/* <div className="text-[14px] font-normal py-[2px]">
                        <span className="text-[#0000008C]">
                            現場：住所: &nbsp;
                        </span>
                        <span>{data?.address}</span>
                    </div> */}

                    <div className="text-[14px] font-normal py-[2px] text-[rgba(0,0,0,0.55)]">
                        {data?.note === 'undefined' ? '' : data?.note}
                    </div>

                    <div className="text-[14px] font-normal py-[2px]">
                        <span className="text-[#000000]">
                            希望日時: &nbsp;
                        </span>
                        <span>
                            {data?.desired_date &&
                                moment(data?.desired_date).format(
                                    'YYYY年MM月DD日 HH:mm',
                                )}
                        </span>
                    </div>

                    {data?.files?.length &&
                        data?.files?.map((item: any) => (
                            <a
                                href={item?.file_path}
                                className="text-[#000000] !no-underline"
                                download
                                target="_blank"
                            >
                                <div className="flex bg-[#F8F8F8] rounded-xl p-2 my-3">
                                    <img
                                        src={pdfFileIcon}
                                        alt="pdf file icon"
                                    />
                                    <div className="ml-3">
                                        <div className="break-all">
                                            {parseNamePdf(item?.file_path)}. PDF
                                        </div>
                                        <div className="text-[#0000008C]">
                                            {item?.size}
                                        </div>
                                    </div>
                                </div>
                            </a>
                        ))}
                </div>
                <div className="flex justify-end">
                    <button
                        className={clsx(
                            'modal-create-user-button1 d-flex flex-row mr-[10px]',
                            {
                                'cursor-no-drop !text-[#DCDCDC] !border-[#DCDCDC]':
                                    !data?.invoice?.id,
                                'cursor-pointer': data?.invoice?.id,
                            },
                        )}
                        style={{
                            width: '150px',
                            height: '36px',
                            border: '1px solid #215493',
                            color: '#215493',
                        }}
                        type="button"
                        onClick={() =>
                            navigate(
                                `/sp/invoice/${data?.invoice?.id}${queryObj.top === 'true'
                                    ? '?top=true'
                                    : ''
                                }`,
                            )
                        }
                        disabled={!data?.invoice?.id}
                    >
                        請求書へ進む
                    </button>
                    <button
                        className={clsx(
                            'search-user-button !w-[72px] d-flex cursor-pointer flex-column height36 text-[12px]',
                            {
                                '!bg-[#DCDCDC]': data?.status !== 'new_request',
                            },
                        )}
                        type="button"
                        onClick={() =>
                            navigate(`/sp/quote/edit/${data?.id}`)
                        }
                        disabled={data?.status !== 'new_request'}
                    >
                        編集
                    </button>
                </div>
            </div>
        </QuoteDetailStyles>
    );
};

const SPQuoteDetail: FC<ReceivedProps> = (props) => {
    return <SPQuoteDetailLayout {...useQuoteDetail(props)} />;
};

export default SPQuoteDetail;
