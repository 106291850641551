import { ContactStyles } from "./styled"

const SPContact = () => {
  return (
    <ContactStyles>
        <div className="innerWrap">
          <img src="/img/carbon_phone-filled.png" alt="phone" />
          <div>{process.env.REACT_APP_PROJECT_TITLE}</div> 
          <div>{process.env.REACT_APP_COMPANY_ADDRESS}</div>
        </div>
    </ContactStyles>
  )
}

export default SPContact;
